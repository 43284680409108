<template>
  <section id="goodsitem" class="good container">
    <b-row v-if="good">
      <!-- превью -->
      <b-col md="4">
        <ListCard onlyCard="true" :goodItem="good"/>
      </b-col>

      <!-- подробности -->
      <b-col md="8">
        <h1>{{ good.name }}</h1>
        <p class="good__price text-primary">{{ good.price + ' ₽' }}</p>

        <p>Ваш Username в Telegram (после оплаты с вами свяжется оператор... )</p>
        <b-form-input v-model="telegramUsername" placeholder="Введите ваш username, например, @sanches123"></b-form-input>

        <b-button variant="primary" size="lg" class="mt-4">        
          Оплатить
        </b-button>

      </b-col>
    </b-row>
  </section>
</template>

<script>

import ListCard from './ListCard'
import { mapGetters } from "vuex";

export default {
  name: "GoodsItem",
  components: {
    ListCard
  },  
  props: {  
  },
  computed: {
    ...mapGetters('goods', ['goodItem']),
    good(){
      return this.goodItem(this.id)
    }
  },
  data() {
    return { 
      id : this.$route.params.id ? this.$route.params.id : null, 
      telegramUsername: null  
    };
  },
  methods: {},
  watch: {
    $route(to, from) {
      // обрабатываем изменение параметров маршрута...      
      this.id = to.params.id ? to.params.id : null;
      this.checkLogin()
    },
  },
};
</script>

<style lang="scss" scoped>
.good {
  
  margin-top: 5rem;
  margin-bottom: 1rem;

  &__price {
    font-weight: 500;
    font-size: xx-large;
  } 
   
}
</style>
